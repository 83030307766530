import React from "react"
import { Link } from "gatsby"

const Nav = () => {
  return (
    <>
      <div className="absolute top-0 z-50 w-full text-white px-4 sm:px-6 lg:px-8 gradient font-sans">
        <nav className="flex items-center justify-between sm:h-10 lg:justify-start">
          <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
            <div className="flex  items-center justify-between w-full md:w-auto">
              <a href="/#home" aria-label="Home">
                <svg
                  className="h-6 w-6 spin"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#000000"
                >
                  <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM7.88 7.88l-3.54 7.78 7.78-3.54 3.54-7.78-7.78 3.54zM10 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="block md:ml-10 md:pr-4 w-full flex flex-row-reverse">
            <Link
              to="/faq/"
              className="ml-8 font-medium hover:text-gray-900 transition duration-150 ease-in-out"
            >
              F.A.Q
            </Link>
            <Link
              to="/resources/"
              className="ml-8 font-medium hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Thoughts
            </Link>
            <Link
              to="/tools/file-info/"
              className="ml-8 font-medium hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Tools
            </Link>
          </div>
        </nav>
      </div>
    </>
  )
}

export default Nav
