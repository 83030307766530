import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const DemoPage = () => {
  const isLive = false

  return (
    <Layout>
      <h1>It's Offline!</h1>
      <p>
        It seems the explot demo is currently offline (probably to update it to
        the latest version). It should be up momentarily.
      </p>
      <form>
        <h3>Want to know when it's back online?</h3>
        <input type="text" placeholder="Your Email" />
        <input type="submit" value="Notify me" />
      </form>
      <Link to="/">Take me back</Link>
    </Layout>
  )
}

export default DemoPage
